.splash-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    font-size: 4em;
    margin: -16px;
    padding: 16px;
}

.splash {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.small {
    font-size: 0.5em;
}

.smaller {
    font-size: 0.3em;
}

@media screen and (max-width: 615px) {
    .splash {
        font-size: 0.7em;
    }
}