.Talk {
    width: 350px;
    height: 170px;
    background-color: #2e2836;
    padding: 12px;
    border-radius: 6px;
    border: 1px solid #e1aa7d;
    margin-top: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.Talk .holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}

.Talk .name {
    display: block;
    text-transform: uppercase;
    font-size: 1em;
    margin-bottom: 0px;
}

.Talk .icon {
    margin-right: 6px;
    font-size:1.5em;
}

.Talk .date {
    font-size: 0.6em;
    margin-bottom: 12px;
}

.Talk .description {
    font-size: 0.7em;
}

.Talk .tags {
    display: flex;
}

.Talk .tags>div {
    margin-left: 6px;
    font-size: 0.6em;
}

.Talk .tags>div:first-child {
    margin-left: 0;
}

.Talk .ribbon-holder {
    z-index: 1;
    display: flex;
    margin-bottom: -47px;
    margin-right: -62px;
    margin-top: 5px;
    justify-content: right;
    flex-shrink: 0;
}