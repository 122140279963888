.About .body {
    display: flex;
}

.About .text {
    flex-grow: 1;
}

.About .text>div {
    margin: 6px 0;
}

.About .trace-under, .About .trace-around {
    border-style: solid;
    border-color: #b5777e;
}

.About .no-border {
    border-width: 0;
}

.About .trace-under {
    border-bottom-width: 2px;
}

.About .trace-right {
    border-right-width: 2px;
    padding-top: 12px;
}

.About .trace-around {
    padding: 2px;
    border-width: 2px;
    border-radius: 6px;
}

.About .aside {
    font-size: 0.5;
    margin-right: 32px;
}

.About .me {
    width: 256px;
    height: 256px;
    border-radius: 6px;
}

.About .chart {
    width: 630px;
    height: 350px;
}

.About div.no-top-margin {
    margin-top: 0;
}

.About div.no-bottom-margin {
    margin-bottom: 0;
}

.About .half {
    width: 50%;
}

.About .spacer {
    height: 12px;
}

.About h1 {
    margin-bottom: 0;
}

.About .social {
    margin-bottom: 16px;
    display: flex;
}

.About .social>a {
    margin-left: 6px;
    display: block;
    padding-right: 6px;
    border-right: 2px solid #fffdfb;
}

.About .social>a:first-child {
    margin-left: 0;
}

.About .social>a:last-child {
    padding-right: 0;
    border-right: 0;
}

@media screen and (max-width: 615px) {
    .About .chart {
        width: 100%;
        height: 350px;
    }
    .About .half {
        width: 98%;
    }
    .About .body {
        display: flex;
        flex-direction: column-reverse;
    }
    .About .trace-under {
        border-bottom-width: 0;
    }
    .About .aside {
        display: none;
    }
    .About .me {
        align-self: center;
    }
}